import { useEffect, useState } from "react";
import "./../Signup/Signup.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar";

import swal from "sweetalert";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassord] = useState("");
  const login = async () => {
    const user = {
      email,
      password,
    };
    try {
      const respons = await axios.post(`${import.meta.env.VITE_SERVER}/api/login`, user);

      if (!respons?.data?.success) {
        console.log(respons?.data?.massage);
      } else if (respons?.data?.success) {
        localStorage.setItem("user", JSON.stringify(respons?.data?.data));
        swal({
          title: "WELCOME 🙏",
          text: "Your Login Successfully!",
          icon: "success",
        }).then(() => {
          window.location.href = "/";
        });
      } else {
        swal("Pleas SignUP First!", "Creat a acount!", "error").then(() => {
          window.location.href = "/signup";
        });
      }
    } catch (err) {
     
      swal("Oops😣!", "Invalid Email and Password!", "error");
      return;
      console.log(err.massage);
    }
  };

  useEffect(() => {
    const StoredUser = JSON.parse(localStorage.getItem("user") || "{}");

    if (StoredUser?.email) {
      alert("You are alredy logged in!");
      window.location.assign("/");
    }
  }, []);

  return (
    <>
      <Navbar />
      <form>
        <div className="input-box-container">
          <h1 className="title">Login</h1>

          <input
            type="text"
            placeholder="Email"
            className="input-box"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Password"
            className="input-box"
            value={password}
            onChange={(e) => {
              setPassord(e.target.value);
            }}
          />
          <br />

          <button type="button" className="signup-btn" onClick={login}>
           
            Login
          </button>
          <br />
          <br />
          <p className="link-position">
          Create an account?{" "}
            <Link to={"/signup"} className="link">
              Signup
            </Link>{" "}
          </p>
        </div>
      </form>
    </>
  );
};

export default Login;
