import { useForm } from "@formspree/react";
import React from "react";
import "./ContactUs.css";
import swal from "sweetalert";

const ContactUs = () => {
  const [state, handleSubmit] = useForm("meqyawpo");
  if (state.succeeded) {
    swal({
      title: "Thanks to reaching us!! 👍",
      text: "We got your message!!!",
      icon: "success",
    });
    for (const form of document.getElementsByTagName("form")) {
      form.reset();
    }
  }

  return (
    <div className="main-container">
      <div className="strip"></div>
      <div className="contact-us-container">
        <div className="form-container contact-page">
          <form id="contact-form" method="POST" onSubmit={handleSubmit}>
            <div className="form-group-two">
              <input
                type="text"
                placeholder="Enter your name"
                id="name"
                name="name"
                required
              />
            </div>
            <div className="form-group-two">
              <input
                type="email"
                placeholder="Enter a valid email address"
                id="email"
                name="email"
                required
              />
            </div>
            <div className="form-group">
              <textarea
                id="message"
                placeholder="Enter Your Message"
                name="message"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="contact-details-container">
          <h2>Contact Details</h2>
          <ul className="contact-list">
            <li>
              <i className="fa fa-phone"></i> Phone:{" "}
              <a className="text-slate-950 ">+917972919474</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i> Email:{" "}
              <a href="sunderjagholidays@gmail.com" className="text-slate-950">
                sunderjagholidays@gmail.com
              </a>
            </li>
            <li>
              <i className="fa fa-map-marker"></i> Location: Nagpur, Maharastra
              (440027)
            </li>
          </ul>
          <br />
          <ul className="social-media-list">
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-square-facebook social-media-list"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-square-twitter social-media-list"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-linkedin social-media-list"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
