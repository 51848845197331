import { useState } from "react";
import "./Signup.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import swal from "sweetalert";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassord] = useState("");
  const [number, setNumber] = useState("");

  const signup = async () => {
    if (!name) {
      swal("Oops!", "Write your name!", "error");
      return;
    }
    if (!email) {
      swal("Oops!", "Write your Email!", "error");
      return;
    }
    if (!number) {
      swal("Oops!", "Write your number!", "error");
      return;
    }
    if (!password) {
      swal("Oops!", "Write a Password !", "error");
      return;
    }

    try {
      const respons = await axios.post(`${import.meta.env.VITE_SERVER}/api/signup`, {
        name: name,
        email: email,
        password: password,
        mobile: number,
      });

      if (respons?.data?.success) {
        swal({
          title: `WELCOME 🙏`,
          text: "Your SignUp Successfully!",
          icon: "success",
        }).then(() => {
          window.location.href = "/login";
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <Navbar />
      <form>
        <div className="input-box-container">
          <h1 className="signup-title">Create Account</h1>

          <input
            type="text"
            placeholder="Name"
            className="input-box"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Email"
            className="input-box"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Mobile"
            className="input-box"
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Password"
            className="input-box"
            value={password}
            onChange={(e) => {
              setPassord(e.target.value);
            }}
          /> 

          <button type="button" className="signup-btn" onClick={signup}>
            {" "}
            Signup
          </button>
          <p className="link-position signup-link">
            Already have an account?{" "}
            <Link to={"/login"} className="link">
              Login
            </Link>{" "}
          </p>
        </div>
      </form>
    </>
  );
};
export default Signup;
