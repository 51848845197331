import React, { useEffect, useState } from "react";
import axios from "axios";
import "./OurPlanPackageCard.css";
import { Link } from "react-router-dom";

const OurPlanPackageCards = () => {
  const [packages, setPackages] = useState([]);

  const loadPackages = async () => {
    let PackegesData;
    try {
      // Use the environment variable
      PackegesData = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/packages`);
    } catch (err) {
      console.log(err);
    }
    setPackages(PackegesData?.data);
  };

  useEffect(() => {
    loadPackages();
  }, []);

  useEffect(() => {
    console.log(packages);
  }, [packages]);

  return (
    <div>
      {packages.map((item) => (
        item && (
          <div key={item.id} className="main-card-image-price">
            <div className="inmain-image">
              <img src={item.templateimage || './default-image.png'} alt="" />
            </div>

            <div className="inmain-content">
              <h2 className="heading">{item.name || 'No name available'}</h2>
              <div className="mini-con">
                <img src="./accomadation.png" alt="" />
                <img src="./food.png" alt="" />
                <img src="./car.png" alt="" />
                <img src="./train.png" alt="" />
                <img src="./airplane.png" alt="" />
                <img src="./no-tax.png" alt="" />
              </div>
              <p className="description">{item.brief || 'No description available'}</p>
            </div>
            <div className="inmain-price">
              <div className="inmain-price-details">
                <div className="duration-left">
                  <h4>Tour Duration</h4>
                  <h3>{item.duration || 'N/A'}</h3>
                </div>
                <div className="duration-right">
                  <h4>Base Price</h4>
                  <div className="paise-duration">
                    <h3>
                      <span className="text-red-500">₹</span>
                      {item.rupee || '0'}
                    </h3>
                  </div>
                </div>
              </div>
              <Link to={`/package/${item.id}`}>
                <button>Full Details</button>
              </Link>
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default OurPlanPackageCards;
