import React, { useState, useEffect } from "react";
import {
  faClock,
  faPhone,
  faUsers,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link, useParams } from "react-router-dom";
import "./PackageInfo.css"; // Import the CSS file
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Carousals from "./Carousals";
import TickList from "./TickList";
import Accordion from "./Accordion";
// import ReviewPage from "./ReviewPage";
import TourBooking from "./TourBooking";
import axios from "axios";

const PackageInfo = () => {
  const { id } = useParams();

  const [packages, setPackages] = useState(null);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_SERVER}/api/packages/${id}`);
        setPackages(response?.data);
      } catch (err) {
        console.error("Fetching error", err);
      }
    };

    fetchData();
  }, [id]);

  if (!packages) {
    return <div>Loading...</div>;
  }

  const {
    name,
    caption,
    description,
    time,
    Phone,
    group,
    language,
    price,
    prices,
    inclusion,
    exclusion,
    photos,
    days,
  } = packages;


  return (
    <>
      <Navbar />
      <div className="main-package flex justify-between">
        <div className="inner-left ml-6">
          <div className="inner-left-header">
            <div className="inner-left-heading">
              <h1 className="mt-4 city-name mb-0 text-blue-600">
                {name}
              </h1>
              <h4 className="text-2xl text-black-600 mb-1 caption">
                {caption}
              </h4>
            </div>
            <div className="line-3 mb-4">
              <hr className="border-t-2 border-gray-300" />
            </div>
          </div>
          <div className="package-content">
            <div className="flex">
              <div className="flex mx-3">
                <FontAwesomeIcon
                  icon={faClock}
                  className="text-3xl mt-3 ml-5 mb-5 mr-5 text-blue-400 icon"
                />
                <div className="time">
                  <span className="Duration text-blue-600">Duration </span>
                  <span className="mt-1 mb-2 time-font">{time}</span>
                </div>
              </div>
              <div className="flex mx-3 border-l border-gray-500 h-18">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="text-3xl mt-3 ml-5 mb-5 mr-5 text-blue-400 icon"
                />
                <div className="time">
                  <span className="Duration text-blue-600">Contact Us </span>
                  <span className="mt-1 mb-2 time-font">{Phone}</span>
                </div>
              </div>
              <div className="flex mx-3 border-l border-gray-500 h-18">
                <FontAwesomeIcon
                  icon={faUsers}
                  className="text-3xl mt-3 ml-5 mb-5 mr-5 text-blue-400 icon"
                />
                <div className="time">
                  <span className="Duration text-blue-600">Group-size </span>
                  <span className="mt-1 mb-2 time-font">{group}</span>
                </div>
              </div>
              <div className="flex mx-3 border-l border-gray-500 h-18 display">
                <FontAwesomeIcon
                  icon={faLanguage}
                  className="text-3xl mt-3 ml-5 mb-5 mr-5 text-blue-400 icon"
                />
                <div className="time">
                  <span className="Duration text-blue-600">Language </span>
                  <span className="mt-1 mb-2 time-font">{language}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="inner-left-mid">
            <div className="flex justify-start mt-7 image-container">
              <div className="carousal max-w-3xl object-cover img">
                <Carousals
                  media={
                    photos &&
                    photos.map((source, index) => ({
                      type: index === 3 ? "video" : "image",
                      source,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div className="decription Inc-Exl">
            <h2 className="text-xl mt-8 px-3 py-2 bg-orange-500 rounded-lg w-44 decription-name">
              Description
            </h2>
            <p className="text-lg text-gray-500 description-cont">
              {description}
            </p>
          </div>

          <div className="Inc-Exl">
            <span className="text-2xl font-semibold text-blue-600 ml-4">
              Inclusion
            </span>
            <div className="my-3">
              <TickList items={inclusion} />
            </div>
          </div>

          <div className="Inc-Exl">
            <span className="text-2xl font-semibold text-blue-600 ml-4">
              Exclusion
            </span>
            <div className="my-3">
              <TickList items={exclusion} isExcluded={true} />
            </div>
          </div>

          <div>
            <div>
              <p className="text-3xl font-semibold text-blue-600 mx-4 mt-7">
                Itinerary
              </p>
            </div>
            <div className="accordion mx-12" id="DownUp">
              <Accordion sections={days} />
            </div>
          </div>
        </div>

        <div className="inner-right mt-8">
          <div className="sold-row">
            <div className="bg-blue-700 w-100% p-5 text-white rounded-lg mb-2">
              <span className="text-xl">{price}</span>
            </div>

            <div>
              {/* <TourBooking key={packages.id} packages={prices} /> */}
              <TourBooking key={packages.id} city={packages} />

            </div>
            <div className="bg-blue-700 w-50% p-3 text-white rounded-lg mb-2">
              <span className="text-xl"> Book Now</span>
            </div>

            <div className="line-3">
              <hr className="border-t-2 border-gray-300" />
            </div>

            <p className="text-lg text-gray-500">
              There is a lot{" "}
              <span id="explore">
                <Link className="link font-bold" to={`/plan`}>
                  More Plan
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
                }


export default PackageInfo;
